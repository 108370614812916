import PropTypes from 'prop-types'

const DefaultLayout = ({ children }) => <main>{children}</main>

DefaultLayout.propTypes = {
  children: PropTypes.node
}

DefaultLayout.defaultProps = {
  children: null
}

export default DefaultLayout
