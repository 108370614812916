import { Suspense, lazy } from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import { Loader } from '@moneymade/moneymade-ui'

import DefaultLayout from 'layouts/DefaultLayout'

const AppV1 = lazy(() => import('components/v1/App/App'))
const AppV2 = lazy(() => import('components/v2/App/App'))
const Init = lazy(() => import('components/v2/Init/Init'))

const Router = () => (
  <BrowserRouter>
    <DefaultLayout>
      <Suspense fallback={<Loader height="100vh" />}>
        <Route path={['/', '/v1']} exact>
          <AppV1 />
        </Route>

        <Route path="/v2" exact>
          <Init>
            <AppV2 />
          </Init>
        </Route>

        <Route path={['/verification', '/v2/verification']}>
          <Init verification>
            <AppV2 />
          </Init>
        </Route>
      </Suspense>
    </DefaultLayout>
  </BrowserRouter>
)

export default Router
